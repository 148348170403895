<template>
  <div :class="props.className?.wrapper">
    <label
      v-if="props.label"
      :for="id"
      class="py-0 col-form-label-sm"
      :class="[props.className?.label, props.required ? 'label-required': '']"
    >
      {{ props.label }}
    </label>
    <div :class="props.className?.inputWrapper">
      <div>
        <!--TODO:  Нужно рефакторить-->
        <div
          class="position-relative d-flex align-items-center gap-2"
          :class="props.className?.inputContainer"
        >
        <input
          ref="input"
          v-if="props.type === 'number'"
          v-number="props.config"
          :disabled="props.disabled"
          v-model="inputModel"
          type="text"
          class="form-control form-control-sm"
          :id="id"
          :class="[props.className?.input, {'is-invalid': props.error, 'has-icon': props.icon}]"
          :autocomplete="props.autocomplete"
          :inputmode="props.inputmode"
          :placeholder="props.placeholder"
        >
        <input
          ref="input"
          v-else
          :disabled="props.disabled"
          v-model="inputModel"
          :type="props.type"
          class="form-control form-control-sm"
          :id="id"
          :class="[props.className?.input, {'is-invalid': props.error, 'has-icon': props.icon}]"
          :autocomplete="props.autocomplete"
          :inputmode="props.inputmode"
          :placeholder="props.placeholder"
        >
        <span
          v-if="props.icon"
          class="icon material-icons fs-5"
        >
          {{props.icon}}
        </span>
        <div
          v-if="hasAfter"
          :class="props.className?.after"
        >
          <slot name="after"/>
        </div>
      </div>
      <span
        v-if="props.description"
        class="color-grey fs-9"
        :class="props.className?.description"
      >
        {{ props.description }}
      </span>
      <div
        v-if="props.error && typeof props.error === 'string'"
        class="small color-error my-1"
      >
        {{ props.error }}
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onActivated, onBeforeUpdate, onMounted, ref, useSlots } from 'vue';
import stringHelper from '@/common/helpers/stringHelper';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  className: {
    type: Object,
  },
  value: {
    type: [String, Number]
  },
  error: {
    type: [String, Boolean]
  },
  required: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'text'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
  },
  config: {
     type: Object,
     default: () => ({
         point: false,
         minus: false,
         firstZero: false,
     })
  },
  focusOnInit: Boolean,
  autocomplete: {
      type: String,
      default: 'off'
  },
  inputmode: {
      type: [String, null],
      default: null
  },
  description: String,
});

const slots = useSlots();
const hasAfter = ref(!!slots.after);
const input = ref(null);

const id = stringHelper.getRandomUUID();

const emit = defineEmits(['update:value']);

const inputModel = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('update:value', value);
  }}
);

onMounted(() => {
    if (props.focusOnInit) {
        input.value.focus();
    }
})

onActivated(() => {
    if (props.focusOnInit) {
        input.value.focus();
    }
})

onBeforeUpdate(() => {
    hasAfter.value = !!slots.after;
});
</script>

<style scoped>
.has-icon {
  padding-right: 33px;
}

.icon {
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
}
</style>
