export const ITEMS_TO_SHORT_LIST_COUNT = 2;
export const ITEMS_IN_SHORT_LIST_COUNT = 3;

export const DAYJS_PATTERNS = {
    ISO_DATE: 'YYYY-MM-DD',
    ISO_DATE_TIME: 'YYYY-MM-DD HH:mm',
    ISO_MONTH_DAY: 'MM-DD',
    EUROPEAN_DATE: 'DD.MM.YYYY',
    EUROPEAN_DAY_MONTH: 'DD.MM',
    VERBOSE_SHORT_DATE: 'D MMM YYYY',
    VERBOSE_SHORT_DATE_TIME: 'D MMM YYYY HH:mm',
    VERBOSE_SHORT_DAY_MONTH_TIME: 'D MMM HH:mm',
    VERBOSE_SHORT_MONTH: 'MMM',
};

export const periodEnum = {
    THIS_WEEK: 'this-week',
    PREV_WEEK: 'prev-week',
    THIS_MONTH: 'this-month',
    PREV_MONTH: 'prev-month',
    PAST_THREE_MONTHS: 'past-three-months',
    THIS_YEAR: 'this-year',
    PREV_YEAR: 'prev-year',
    ALL_TIME: 'all-time',
};
