<template>
    <div class="input-group flex-nowrap" :class="'date-picker-'+size">
        <input
            ref="input"
            type="text"
            :class="[inputClass, classList.input]"
            @click="open"
            readonly="readonly"
            :disabled="disabled"
            :placeholder="placeholder ?? t('date_picker_simple.default')"
            autocomplete="off"
        />
        <button
            type="button"
            class="btn btn-link border-btn text-decoration-none d-flex align-items-center"
            :disabled="disabled"
            @click="onClick"
        >
            <span class="material-icons">
              {{ isReset ? 'close' : 'date_range' }}
            </span>
        </button>
    </div>
</template>
<script>
    import flatpickr from "flatpickr";
    import 'flatpickr/dist/flatpickr.css';
    import { Russian } from "flatpickr/dist/l10n/ru.js"
    import { useI18n } from 'vue-i18n';
    import { ref, watch, computed, onMounted } from 'vue';
    import dateHelper from "@/common/helpers/dateHelper";
    export default {
        name: 'DatePickerSimple',
        emits: [
            'update',
        ],
        props : {
            size: {
                default: 'small',
            },
            date: {
                default: null,
            },
            placeholder: {
                default: null,
            },
            viewFormat: {
                default: null,
            },
            emitUpdate: {
                default: true,
            },
            static: {
                type: Boolean,
                default: true,
            },
            position: {
                type: String,
                default: '',
            },
            maxDate: {
                type: Date,
                default: null,
            },
            minDate: {
                type: Date,
                default: null,
            },
            altInput: {
                type: Boolean,
                default: true,
            },
            appendTo: {
                type: Object,
            },
            showResetButton: {
                type: Boolean,
                default: false
            },
            disabled: Boolean,
            invalid: Boolean,
            classList: {
                type: Object,
                default: () => ({}),
            },
        },
        setup(props, { emit }) {
            let picker = null;
            const { t, locale } = useI18n();
            const input = ref(null);
            const current = ref(props.date ? new Date(props.date.getTime()) : null);

            const isReset = computed(() => props.showResetButton && props.date);

            watch(() => props.date, () => refresh());

            const refresh = () => {
                current.value = props.date;
                if (picker) {
                    picker.setDate(current.value);
                } else {
                    updateInputValue();
                }
            }

            const reset = () => {
                input.value.value = '';
                emit('update', {
                    date: null
                });
            }

            const formatDate = (date) => {
                if (props.viewFormat === 'd M') {
                    return dateHelper.dateTitle(date, false);
                } else if(props.viewFormat === 'd.m.Y') {
                    return dateHelper.viewDateString(date);
                } else if(props.viewFormat === 'd.m.y') {
                    return dateHelper.getDateOrDayDiffName(date)
                } else if(props.viewFormat === 'd.m') {
                    return dateHelper.getEuropeanDayMonth(date)
                } else {
                    return dateHelper.dateTitle(date);
                }
            };

            /*
                Для пересчета координат при скролле и static = false,
                когда пикер размещается в контейнере с overflow: hidden https://github.com/flatpickr/flatpickr/issues/1160
             */

            const appendFlatpickrToScroll = (picker, parent) => {
                picker.config.onOpen.push(() => {
                    parent.addEventListener("scroll", onScroll);
                });

                picker.config.onClose.push(() => {
                    parent.removeEventListener("scroll", onScroll);
                });

                const onScroll = () => {
                    picker._positionCalendar();
                }
            }

            const open = e => {
                input.value.blur();

                if (picker) {
                    picker.destroy();
                }

                const params = {
                    position: props.position,
                    maxDate: props.maxDate,
                    minDate: props.minDate,
                    static: props.static,
                    defaultDate: current.value,
                    dateFormat: props.viewFormat,
                    altInput: props.altInput,
                    appendTo: props.appendTo,
                    formatDate,
                    onClose: (selectedDates, dateStr, instance) => {
                        picker = null;
                        current.value = instance.selectedDates.length ? instance.selectedDates[0] : null;
                        emit('update', {
                          date: current.value
                        });
                        instance.destroy();
                    }
                };

                if (locale.value == 'ru') {
                    params.locale = Russian;
                }

                picker = flatpickr(e.target, params);

                if (props.appendTo && props.static === false) {
                    appendFlatpickrToScroll(picker, props.appendTo);
                }

                picker.open();
            }

            const updateInputValue = () => {
                input.value.value = props.date ? formatDate(props.date) : '';
            }

            const onClick = () => {
                if (isReset.value) {
                    reset();
                } else {
                    input.value.click();
                }
            };

            onMounted(() => {
                updateInputValue();
            });

            return {
                t,
                current,
                input,
                open,
                refresh,
                onClick,
                isReset,
                inputClass: computed(() => {
                    let result = '';
                    if (props.invalid) {
                        result += 'is-invalid ';
                    }

                    if (props.size == 'mini') {
                        result += 'form-control input-date bg-white';
                    } else if (props.size == 'small') {
                        result += 'form-control form-control-sm input-date bg-white';
                    } else if (props.size == 'short') {
                        result += 'form-control form-control-sm input-date bg-white';
                    } else if (props.size == 'xs') {
                        result += 'form-control form-control-sm input-date bg-white';
                    }

                    return result || null;
                }),
            }
        }
    }
</script>
<style lang="css" src="@/common/assets/css/DatePickerSimple.css"></style>
