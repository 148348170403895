import {createApp} from 'vue';
import App from '@/auth/App';
import ruCommon from '@/common/i18n/ru';
import enCommon from '@/common/i18n/en';
import ru from '@/auth/i18n/ru';
import en from '@/auth/i18n/en';
import {init, initI18} from '@/common/init';
import {directives} from '@/common/directives/directives';
import sentry from '@/common/sentry';
import router from '@/auth/router';
import Maska from 'maska';
import {maskito} from '@maskito/vue';
import { initYandexMetrika } from 'yandex-metrika-vue3';
import { YM_CONFIG } from '@/common/yandexMetrika';

init.token();

const isActiveYM = process.env.VUE_APP_YANDEX_METRIKA_IS_ACTIVE === 'true';
const app = createApp(App);
app.use(router)
app.use(Maska);
app.use(initI18({...ruCommon, ...ru}, {...enCommon, ...en}));
for (let i in directives) {
  app.directive(i, directives[i]);
}
app.directive('maskito', maskito);
sentry.init(app);
if (isActiveYM) {
  app.use(initYandexMetrika, YM_CONFIG);
}
app.mount('#app');


