export const SETTINGS_RESTRICTION_TAB_VIDEO = 'https://www.youtube.com/embed/rFuKsIErbEk';
export const Y_KASSA_PAYMENTS_MANUAL = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/article/62/category/15/';
export const MONETA_PAYMENTS_MANUAL = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/article/55/category/15/';
export const AFFILIATE_PROGRAM = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/article/51/category/10/';
export const BONUS_POLICY = 'https://realtycalendar.ru/legal/bonuses.pdf';
export const ADDITIONAL_GUEST_LINK = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/article/70/category/12/';
// убрать при выполнении таска на удаление wallet 1
export const KNOWLEDGE_BASE_URL = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/category/7';
export const WALLET_1_PAYMENTS_MANUAL = 'https://help.realtycalendar.ru/hc/ru/articles/360016059111-%D0%9D%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D1%8B';
export const USER_AGREED='https://realtycalendar.ru/legal/agreement.pdf';
export const PRIVACY_AGREED='https://new.realtycalendar.ru/soglasie-personalinye-dannyx';
export const ADS_AGREED='https://new.realtycalendar.ru/soglasie-prymye-kontakty';
export const TG_SUPPORT = 'https://t.me/RealtyCalendar_support_bot';
export const DEPOSITS_ON_LINK = 'https://new.realtycalendar.ru/deposit';
export const PUBLIC_OFERTA = 'https://new.realtycalendar.ru/public-oferta';
export const TARRIFS = 'https://www.moneta.ru/info/d/ru/public/users/nko/RealtyCalendarindividuals.pdf';
export const SPECIAL_CONDITIONS_SETTINGS = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/article/77/category/13/';
export const NEW_MIN_STAY_THROUGH_INFO = 'https://realtycalendar.helpdeskeddy.com/ru/knowledge_base/article/138/category/13';

export const WHATSAPP_SEND = 'https://api.whatsapp.com/send/?phone={phone}&text={text}&type=phone_number&app_absent=0'
