import useToast from '@/common/api/useToast';
const { show } = useToast();

const copyText = (text, toastText) => {
    navigator.clipboard.writeText(text);
    if (toastText) {
        show({ text: toastText });
    }
};

const formatCurrency = (number, options = {}) => {
    return new Intl.NumberFormat('ru-RU', { ...options }).format(number);
};

// Пустое поле в редакторе всегда <p><br></p>
const isEditorEmptyString = (string) => {
    return (
        string === '<p><br></p>' ||
        string === '<p>\n</p>' || // ловил при сохранении на v1 и просмотре на v2
        !string
    );
};

const editorInitialStringNormalizer = (string) => {
    return isEditorEmptyString(string) ? '<p><br></p>' : string;
}

const getRandomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const getRandomUUID = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

const getNestedValue = (model, keys) => keys.reduce((acc, item) => acc[item], model);

const getStringWithLink = (str) => {
    const target = str.includes('http') ? '_blank' : '_self';
    return str.replace(
        /(\()(.*?)(\)\[)(.*?)(\])/g,
        `<a href='$2' target='${target}' class='link text-decoration-none'>$4</a>`,
    );
};

const getIsEmail = (string) => {
    return string.includes('@');
};

const getDigitPhone = (phone) => {
    return phone.replace(/\D/g, '');
};

/**
 * Форматирует телефонный номер, добавляя или удаляя символ '+' в начале
 * @param {string} phone - Номер телефона
 * @param {boolean} [needPlus=true] - Нужно ли добавить '+' в начало номера
 * @returns {string} Отформатированный номер телефона
 * @example
 * formatPhonePlus('+79991234567', false) // '79991234567'
 * formatPhonePlus('79991234567', true) // '+79991234567'
 */
const formatPhonePlus = (phone, needPlus = true) => {
    if (!phone) return null;

    const isPhoneWithPlus = phone.startsWith('+');

    if (isPhoneWithPlus && !needPlus) {
        return phone.slice(1);
    } else if (!isPhoneWithPlus && needPlus) {
        return `+${phone}`;
    } else {
        return phone;
    }
};

/** Разделяет строку на две части по первой точке */
const splitFirstPoint = str => {
  const firstPointIndex = str.indexOf('.');
  if (firstPointIndex === -1) {
      return [str];
  }
  return [str.slice(0, firstPointIndex), str.slice(firstPointIndex + 1)];
};

export default {
  copyText,
  formatCurrency,
  isEditorEmptyString,
  editorInitialStringNormalizer,
  getRandomString,
  getRandomUUID,
  getNestedValue,
  getStringWithLink,
  getDigitPhone,
  formatPhonePlus,
  getIsEmail,
  splitFirstPoint,
}
