<template>
    <div class="settings-wrapper br-4 d-flex flex-wrap gap-3 p-3">
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center flex-shrink-0">
                <span class="me-3">
                    {{ t('cart.arrival') }}
                </span>
                <DatePickerSimple
                    :date="props.beginDate"
                    :disabled="props.isDisabled"
                    @update="emit('update:beginDate', $event)"
                />
            </div>
            <div
                v-if="props.errors.begin_date"
                class="invalid-feedback d-block"
            >
                {{ props.errors.begin_date }}
            </div>
        </div>

        <div class="d-flex flex-column">
            <div class="d-flex align-items-center flex-shrink-0">
                <span class="me-3">
                    {{ t('cart.departure') }}
                </span>
                <DatePickerSimple
                    :date="props.endDate"
                    :disabled="props.isDisabled"
                    @update="emit('update:endDate', $event)"
                />
            </div>
            <div
                v-if="props.errors.end_date"
                class="invalid-feedback d-block"
            >
                {{ props.errors.end_date }}
            </div>
        </div>

        <NativeSelect
            :label="t('cart.guests')"
            :options="guestOptions"
            v-model:value="guestsCount"
            :disabled="props.isDisabled"
            :error="props.errors.guests_count"
            :className="{ wrapper: 'd-flex align-items-center flex-shrink-0', label: 'me-3', select: ''}"
        />

        <div
            v-if="!extraChargeInfo"
            class="d-flex"
        >
            <CombinedInput
                :className="{
                    input: 'min-width-60 max-width-80',
                    wrapper: 'd-flex align-items-center flex-shrink-0 me-2',
                    select: 'max-width-60',
                    inputWrapper: 'me-1',
                    label: 'me-3'
                }"
                :label="t('cart.extra_charge_label')"
                :disabled="props.isDisabled"
                :error-input="props.errors.extra_charge"
                :options="extraChargeOptions"
                v-model:inputValue="extraCharge"
                v-model:selectValue="extraChargeType"
                :inputConfig="{minus: true, point: false}"
            />
            <button
                type="button"
                class="btn btn-primary btn-sm"
                :disabled="props.isDisabled"
                @click="updateExtraCharge"
            >
                {{ t('cart.add') }}
            </button>
        </div>

        <div
            v-else
            class="d-flex flex-column"
        >
            <div class="d-flex align-items-center flex-shrink-0">
                <span class="me-2">
                    {{ t('cart.added_extra_charge') }}
                </span>
                <span class="btn btn-sm d-flex align-items-center extra-charge-info px-3">
                    <span class="me-2">
                        {{ extraChargeInfo }}
                    </span>
                    <button
                        type="button"
                        class="btn-close fs-9"
                        :disabled="props.isDisabled"
                        @click="clearExtraCharge"
                    />
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch, defineModel } from 'vue';
import NativeSelect from '@/common/components/inputs/NativeSelect.vue';
import CombinedInput from '@/common/components/inputs/CombinedInput.vue';
import DatePickerSimple from '@/common/components/DatePickerSimple.vue';
import stringHelper from '@/common/helpers/stringHelper';

const { t } = useI18n();

const guestsCount = defineModel('guestsCount');

const props = defineProps({
    isDisabled: Boolean,
    beginDate: [Date, null],
    endDate: [Date, null],
    extraCharge: Number,
    extraChargeType: String,
    errors: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits([
    'update:beginDate',
    'update:endDate',
    'update:extraCharge',
]);

const extraChargeOptions = [
    {
        id: 'percent',
        name: '%',
    },
    {
        id: 'currency',
        name: t('agencyCurrency'),
    },
];

const MAX_GUESTS = 20;
const guestOptions = [];
for (let index = 1; index <= MAX_GUESTS; index++) {
    guestOptions.push({
        name: index,
        id: index,
    });
}

const extraCharge = ref(props.extraCharge);
const extraChargeType = ref(props.extraChargeType);

const extraChargeInfo = computed(() => {
    const currentExtraChargeTypeValue = extraChargeOptions.find((option) => option.id === props.extraChargeType).name;
    return props.extraCharge
        ? t('cart.extra_charge', {
              price: `${props.extraChargeType === 'currency' ? stringHelper.formatCurrency(props.extraCharge) : props.extraCharge}`,
              type: currentExtraChargeTypeValue,
          })
        : null;
});

const updateExtraCharge = () => {
    emit('update:extraCharge', {value: extraCharge.value, type: extraChargeType.value});
};

const clearExtraCharge = () => {
    extraCharge.value = 0;
    updateExtraCharge();
};

watch(
    () => props.extraCharge,
    (value) => {
        extraCharge.value = value;
    },
);

watch(
    () => props.extraChargeType,
    (value) => {
        extraChargeType.value = value;
    },
);
</script>

<style scoped>
.settings-wrapper {
    background-color: var(--color-bg-blue-light);
}

.extra-charge-info {
    border-radius: 16px;
    background-color: white;
}
</style>
