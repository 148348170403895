import { computed, defineAsyncComponent } from 'vue';
import { isAccess, userAccess } from '@/common/rbac';
import commonStore from '@/common/commonStore';
import { useI18n } from 'vue-i18n';
import { KNOWLEDGE_BASE_URL } from '@/common/const/staticLinks';

export default function useBaseNavigation() {
    const {t} = useI18n();

    const apartmentId = computed(() => commonStore.state.user?.agency.id);

    const top = computed(() => {
        return apartmentId.value ? [
            {
                active: commonStore.state.currentPage === 'chessmate',
                name: 'calendars',
                title: t('left_side.calendar'),
                icon: defineAsyncComponent(() => import('@/common/components/icons/IconCalendar')),
                url: '/chessmate/',
                isShow: isAccess('calendars')
            },
            {
                active: false,
                name: 'request_bookings',
                title: t('left_side.clients_and_reservations'),
                icon: defineAsyncComponent(() => import('@/common/components/icons/IconKey')),
                url: `/agencies/${apartmentId.value}/apartments/requests_bookings`,
                isShow: isAccess('request_bookings')
            },
            {
                active: false,
                name: 'partners',
                title: t('left_side.partners'),
                icon: defineAsyncComponent(() => import('@/common/components/icons/IconPartners')),
                url: '/partners',
                isShow: isAccess('partners'),
            },
            {
                active: commonStore.state.currentPage === 'channel_manager',
                name: 'icalendar_sources',
                title: t('left_side.channel_manager'),
                iconName: 'channel_manager',
                error_counter: commonStore.getters.invalidIntegrationsCount,
                url: commonStore.getters.hasFeatureFlag('channel_manager') ? '/channel-manager' : '/icalendar_sources',
                isShow: isAccess('icalendar_sources'),
            },
            {
                active: false,
                name: 'statistics',
                title: t('left_side.statistics'),
                icon: defineAsyncComponent(() => import('@/common/components/icons/IconStatistic')),
                url: '/statistic',
                isShow: isAccess('statistics'),
            },
        ].filter(item => item.isShow) : [];
    });

    const bottom = computed(() => {
        return apartmentId.value ? [
            {
                active: commonStore.state.currentPage === 'settings',
                name: 'settings',
                title: t('left_side.settings'),
                icon: defineAsyncComponent(() => import('@/common/components/icons/IconSettings')),
                url: commonStore.getters.hasFeatureFlag('settings_v2') ? '/settings' : `/agencies/${apartmentId.value}`,
                isShow: isAccess('settings'),
            },
            {
                active: false,
                name: 'help',
                title: t('left_side.faq'),
                icon: defineAsyncComponent(() => import('@/common/components/icons/IconHelp')),
                url: KNOWLEDGE_BASE_URL,
                isShow: isAccess('help'),
                blank: true,
            },
        ].filter(item => item.isShow) : [];
    });

    const getClosestPageForRedirect = () => {
        return [...top.value, ...bottom.value]
            .filter(page => page.name !== 'help')
            .find(page => userAccess.value.includes(page.name));
    }

    const ensureNavigateToPage = (hasAccess) => {
        return new Promise((resolve, reject) => {
        // TODO: Удалить проверку на ff после подключения всех пользователей
            if (!commonStore.getters.hasFeatureFlag('cleaner_permissions')) {
                resolve();
            }
            if (hasAccess) {
                resolve();
            } else {
                reject({isNoAccess: true, goTo: getClosestPageForRedirect().url});
            }
        });
    }

    return {top, bottom, ensureNavigateToPage}
}
