export default {
    auth: {
        title: 'Service for\u00A0managing daily real estate',
        sign_in: {
            title: 'Authorization',
            login_title: 'Email or phone',
            password_title: 'Password',
            button_title: 'Login',
            forget_password: 'Forgot your password?',
            restore_password: 'Recover password',
            no_account: 'No account?',
            register: 'Register'
        },
        restore_password: {
            title: 'Password recovery',
            username_title: 'Phone or email',
            next: 'Next',
            go_back: 'Go back',
            change_email: 'Change email',
            change_phone: 'Change phone number',
            email_sent: 'A code has been sent to your email. If you can\'t find the email, check your Spam folder.',
            sms_sent: 'A code has been sent to your phone number.',
            code: 'Activation code',
            get_new_code: 'Get a new code',
            password_title: 'Create a password (minimum 8 characters)',
            finish: 'Finish'
        },
        sign_up: {
            title_employee: 'Employee registration',
            title_renter: 'Landlord registration',
            name: 'Name',
            phone_with_code: 'Phone (confirmation code will be sent)',
            phone: 'Phone',
            email_with_code: 'Email (confirmation code will be sent)',
            is_landlord: 'I rent out housing',
            is_renter: 'I want to rent housing',
            is_employee: 'I am an employee of the landlord',
            terms_agreed: 'I have read and agree to the {User Agreement|{user_agreed}} {br} and {Consent to the processing of personal data|{privacy_agreed}}',
            ads_agreed: 'I agree {to receive advertising mailings|{ads_agreed}}',
            next: 'Next',
            alert_modal: 'RealtyCalendar is a service for landlords. {br} Unfortunately, you will not be able to rent a home with our help. To search for accommodation, you can use daily rental aggregators: Avito, Sutochno, Ostrovok, Yandex Travel, etc.',
            close: 'Close',
            has_account: 'Already registered?',
            sign_in: 'Log in',
            sms_code: 'Enter the code from the SMS',
            email_code: 'Enter the code from the email',
            sms_confirm: 'A code has been sent to your number {phone}',
            email_confirm: 'A code has been sent to your {email} address. If you don\'t see the email, check your Spam folder.',
            get_code: 'Get a new code',
            password: 'Create a password (minimum 8 characters)',
            email: 'Enter your email',
            your_email: 'Your email',
            back: 'Back',
            how_many_objects: 'How many objects do you manage?',
            promocode: 'Promo code',
            i_have_promocode: 'I have a promo code',
            just_start: '0 (only I\'m starting)',
            one: '1',
            under_five: '2 - 4',
            under_ten: '5 - 9',
            under_twenty: '10 - 20',
            more_than_twenty: 'more than 20',
            finish: 'Finish registration',
            error_phone: 'The phone number is in an invalid format',
        },
        confirm_email: {
            title: 'Email confirmation',
            text: 'Thank you! Email confirmed.',
            go_to_lk: 'Go to personal account'
        }
    }
};
