import dayjs from 'dayjs';
import { i18n } from '@/common/init';
import { DAYJS_PATTERNS, periodEnum } from '@/common/const/const';

const parseDate = (date) => {
    if (typeof date === 'string') {
        const dateArray = date.split('-');
        if (dateArray.length == 3) {
            date = new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]));
        } else {
            date = new Date(date);
        }
    }
    return date;
};
const addToDate = (date, adds = []) => {
    let tempDate = new Date(date.getTime());
    adds.forEach((value) => tempDate.setDate(tempDate.getDate() + value));
    return tempDate;
};
const daysDiff = (dateBegin, dateEnd) => {
    if (!dateBegin || !dateEnd) return null;
    return Math.abs(Math.round((new Date(dateBegin).getTime() - new Date(dateEnd).getTime()) / (1000 * 3600 * 24)));
};
const daysDiffTrunc = (dateBegin, dateEnd, abs = false) => {
    if (!dateBegin || !dateEnd) return null;
    const result = Math.trunc((new Date(dateBegin).getTime() - new Date(dateEnd).getTime()) / (1000 * 3600 * 24));
    return abs ? Math.abs(result) : result;
};
const formatMonthString = (date) => {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2);
};
const monthTitle = (date, capitalize = false, withYear = false) => {
    let title = date.toLocaleString(i18n.global.locale.value, { month: 'long' });
    title = capitalize ? title.charAt(0).toUpperCase() + title.slice(1) : title;
    if (withYear) {
        title = title + ([0, 11].indexOf(date.getMonth()) == -1 ? '' : ', ' + date.getFullYear());
    }
    return title;
};
const dateTitle = (date, withYear = true) => {
    if (typeof date === 'string') {
        date = parseDate(date);
    }
    if (withYear) {
        return date.toLocaleString(i18n.global.locale.value, { year: 'numeric', month: 'long', day: 'numeric' });
    } else {
        return date.toLocaleString(i18n.global.locale.value, { month: 'long', day: 'numeric' });
    }
};
const generateMonths = (year = null) => {
    let list = [];
    if (!year) {
        year = new Date().getFullYear();
    }
    for (let i = 0; i <= 11; i++) {
        const date = new Date(year, i, 3);
        list.push({ id: i, value: formatMonthString(date), title: monthTitle(date, true) });
    }
    return list;
};
const generateFormattedDatesList = (dateBegin, dateEnd, includeBegin = true, uncludeEnd = true) => {
    if (dateBegin > dateEnd) return [];

    const daysCount = daysDiff(dateBegin, dateEnd);

    let list = [];
    for (let i = includeBegin ? 0 : 1; i <= daysCount - (uncludeEnd ? 0 : 1); i++) {
        list.push(formatDateString(addToDate(parseDate(dateBegin), [i])));
    }
    return list;
};
const formatDateString = (date) => {
    if (!date) return;
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
};

const getFullDateTime = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const validateTimeString = (time) => {
    return /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
};

const viewDateString = (date, fullYear = true) => {
    if (!date) return '';
    const year = date.getFullYear().toString();
    const yearResult = fullYear ? year : year.slice(-2);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + yearResult;
};

const getDateOrDayDiffName = (date) => {
    const now = new Date();
    const diff = daysDiffTrunc(date, new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    if (diff === 0) {
        return i18n.global.t('days.today');
    } else if (diff === -1) {
        return i18n.global.t('days.yesterday');
    } else if (diff === 1) {
        return i18n.global.t('days.tomorrow');
    } else {
        return viewDateString(date, false);
    }
};

const getRelativeTime = (time) => {
    if (typeof time === 'string') {
        time = new Date(time);
    }
    let result;
    const timeTemp = new Date(time.getTime());
    if (new Date().setHours(0, 0, 0, 0) === timeTemp.setHours(0, 0, 0, 0)) {
        return time.toLocaleString(i18n.global.locale.value, { hour: '2-digit', minute: '2-digit' });
    } else {
        if (time.getFullYear() === new Date().getFullYear()) {
            result = time.toLocaleString(i18n.global.locale.value, { month: 'long', day: '2-digit' });
        } else {
            result = time.toLocaleString(i18n.global.locale.value, { month: 'long', day: '2-digit', year: 'numeric' });
        }
        return result.replace(' г.', '');
    }
};

const getTimeString = (time) => {
    const hours = Math.floor(time / 3600)
        .toString()
        .padStart(2, '0');
    const minutes = Math.floor((time % 3600) / 60)
        .toString()
        .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`;
};

/** Форматирует дату и время в строку формата YYYY-MM-DD HH:mm */
const getISODateTime = (date) => {
    return dayjs(date).format(DAYJS_PATTERNS.ISO_DATE_TIME);
};

/** Форматирует дату в строку формата YYYY-MM-DD */
const getISODate = (date) => {
    return dayjs(date).format(DAYJS_PATTERNS.ISO_DATE);
};

/** Форматирует дату в строку формата MM-DD */
const getISOMonthDay = (date) => {
    return dayjs(date).format(DAYJS_PATTERNS.ISO_MONTH_DAY);
};

/** Форматирует дату в строку формата DD.MM.YYYY */
const getEuropeanDate = (date) => {
    return dayjs(date).format(DAYJS_PATTERNS.EUROPEAN_DATE);
};

/** Форматирует дату в строку формата DD.MM */
const getEuropeanDayMonth = (date) => {
    return dayjs(date).format(DAYJS_PATTERNS.EUROPEAN_DAY_MONTH);
};

/** Форматирует дату в строку формата "D MMM YYYY" (например, "1 янв 2024") */
const getVerboseShortDate = (date) => {
    const formatted = dayjs(date).format(DAYJS_PATTERNS.VERBOSE_SHORT_DATE);
    const month = formatted.split(' ')[1];
    // dayjs не сокращает названия месяцев на русском
    return formatted.replace(month, month.slice(0, 3));
};

/** Форматирует дату и время в строку формата "D MMM YYYY HH:mm" (например, "1 янв 2024 15:30") */
const getVerboseShortDateTime = (date) => {
    const formatted = dayjs(date).format(DAYJS_PATTERNS.VERBOSE_SHORT_DATE_TIME);
    const month = formatted.split(' ')[1];
    // dayjs не сокращает названия месяцев на русском
    return formatted.replace(month, month.slice(0, 3));
};

/** Форматирует дату и время в строку формата "D MMM HH:mm" (например, "1 янв 15:30") */
const getVerboseShortDayMonthTime = (date) => {
    const formatted = dayjs(date).format(DAYJS_PATTERNS.VERBOSE_SHORT_DAY_MONTH_TIME);
    const month = formatted.split(' ')[1];
    // dayjs не сокращает названия месяцев на русском
    return formatted.replace(month, month.slice(0, 3));
};

/** Возвращает сокращенное название месяца (первые 3 буквы) */
const getVerboseShortMonth = (date) => {
    // dayjs не сокращает названия месяцев на русском
    return dayjs(date).format(DAYJS_PATTERNS.VERBOSE_SHORT_MONTH).slice(0, 3);
};

/** Добавляет или вычитает указанное количество месяцев из даты */
const addMonths = (anyDate, count) => {
    const initialDate = dayjs(anyDate);
    const initialDay = initialDate.date();
    const isLastDayInMonth = initialDay === initialDate.daysInMonth();
    let resultDate = dayjs(initialDate);

    if (!count) {
        return resultDate.toDate();
    }

    for (let i = 0; i < Math.abs(count); i++) {
        let newMonthDate;

        if (count < 0) {
            newMonthDate = resultDate.date(1).subtract(1, 'day');
        } else if (count > 0) {
            newMonthDate = resultDate.date(resultDate.daysInMonth()).add(1, 'day');
        }

        const newMonthSize = newMonthDate.daysInMonth();
        resultDate = newMonthDate.date(isLastDayInMonth ? newMonthSize : Math.min(initialDay, newMonthSize));
    }

    return resultDate.toDate();
};

const getPeriodBoundaryDates = (periodId) => {
    const daysInWeek = 7;
    const monthsInQuart = 3;

    const result = {
        beginDate: null,
        endDate: null,
    };

    if (periodId === periodEnum.ALL_TIME) {
        return result;
    } else if (periodId === periodEnum.THIS_WEEK) {
        result.beginDate = getISODate(dayjs().weekday(0));
        result.endDate = getISODate(dayjs().weekday(daysInWeek - 1));
        //
    } else if (periodId === periodEnum.PREV_WEEK) {
        result.beginDate = getISODate(dayjs().weekday(-daysInWeek));
        result.endDate = getISODate(dayjs().weekday(-1));
        //
    } else if (periodId === periodEnum.THIS_MONTH) {
        const now = dayjs();
        result.beginDate = getISODate(now.date(1));
        result.endDate = getISODate(now.date(now.daysInMonth()));
        //
    } else if (periodId === periodEnum.PREV_MONTH) {
        const endOfLastMonth = dayjs().date(1).subtract(1, 'day');
        result.beginDate = getISODate(endOfLastMonth.date(1));
        result.endDate = getISODate(endOfLastMonth);
        //
    } else if (periodId === periodEnum.PAST_THREE_MONTHS) {
        const now = dayjs();
        result.beginDate = getISODate(addMonths(now.date(1), -(monthsInQuart - 1)));
        result.endDate = getISODate(now.date(now.daysInMonth()));
        //
    } else if (periodId === periodEnum.THIS_YEAR) {
        result.beginDate = getISODate(dayjs().dayOfYear(1));
        result.endDate = getISODate(dayjs(result.beginDate).add(1, 'year').dayOfYear(1).subtract(1, 'day'));
        //
    } else if (periodId === periodEnum.PREV_YEAR) {
        const endOfLastYear = dayjs().dayOfYear(1).subtract(1, 'day');
        result.beginDate = getISODate(endOfLastYear.dayOfYear(1));
        result.endDate = getISODate(endOfLastYear);
    }

    return result;
};

const getPeriodIdByDates = (beginDate, endDate) => {
    const formattedBeginDate = beginDate ? getISODate(beginDate) : null;
    const formattedEndDate = endDate ? getISODate(endDate) : null;

    for (const key in periodEnum) {
        const periodId = periodEnum[key];
        const period = getPeriodBoundaryDates(periodId);

        if (period.beginDate === formattedBeginDate && period.endDate === formattedEndDate) {
            return periodId;
        }
    }
    return null;
};

export default {
    getRelativeTime,
    getFullDateTime,
    parseDate,
    formatDateString,
    viewDateString,
    formatMonthString,
    addToDate,
    generateMonths,
    generateMonthTitles: () => {
        return generateMonths().map((m) => m.title);
    },
    generateFormattedDatesList,
    calculateDateBegin: (dateBegin, dateEnd) => {
        if (!dateEnd) return null;
        if (!dateBegin || dateBegin.getTime() > dateEnd.getTime() - 24 * 60 * 60 * 1000) {
            return new Date(dateEnd.getTime() - 24 * 60 * 60 * 1000);
        } else {
            return dateBegin;
        }
    },
    calculateDateEnd: (dateBegin, dateEnd) => {
        if (!dateBegin) return null;
        if (!dateEnd || dateEnd.getTime() < dateBegin.getTime() + 24 * 60 * 60 * 1000) {
            return new Date(dateBegin.getTime() + 24 * 60 * 60 * 1000);
        } else {
            return dateEnd;
        }
    },
    daysDiff,
    convertSearchToString: (date) => {
        return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
    },
    getMonth(date, type = 'current') {
        let monthDate = date;
        if (type == 'prev') {
            monthDate =
                date.getMonth() == 0
                    ? new Date(date.getFullYear() - 1, 11, 1)
                    : new Date(date.getFullYear(), date.getMonth() - 1, 1);
        } else if (type == 'next') {
            monthDate =
                date.getMonth() == 11
                    ? new Date(date.getFullYear() + 1, 0, 1)
                    : new Date(date.getFullYear(), date.getMonth() + 1, 1);
        }
        return {
            value: formatMonthString(monthDate),
            title: monthTitle(monthDate, true, true),
        };
    },
    compareDates: (date1, date2) => {
        return formatDateString(date1) == formatDateString(date2);
    },
    dateTitle,
    validateTimeString,
    parseTimeString(time) {
        return time && time.length >= 4 && validateTimeString(time);
    },
    dayTitleShort(date, firstUpper = false) {
        const title = date.toLocaleDateString(i18n.global.locale.value, { weekday: 'short' });
        return firstUpper ? title.charAt(0).toUpperCase() + title.slice(1) : title;
    },
    getDateOrDayDiffName,
    getTimeString,
    getISODateTime,
    getISODate,
    getISOMonthDay,
    getEuropeanDate,
    getEuropeanDayMonth,
    getVerboseShortDate,
    getVerboseShortDateTime,
    getVerboseShortDayMonthTime,
    getVerboseShortMonth,
    addMonths,
    getPeriodBoundaryDates,
    getPeriodIdByDates,
};
